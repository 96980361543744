@use '@asset/sass/partial/vars' as *;

body.simple-page {
  background-color: $theme-primary;
  padding-top: 8%;
}

.simple-page-wrap {
  width: 380px;
  margin: 0 auto;
}

.practice-setup {
  background-color: $theme-primary;
  padding-top: 5% !important;
}

.practice-setup-wrap {
  width: 500px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .practice-setup-wrap {
    width: 380px;
    margin: 0 auto;
  }
}

.signup-wrap {
  width: 500px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .signup-wrap {
    width: 380px;
    margin: 0 auto;
  }
}

.simple-page-logo {
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
}
.simple-page-logo a {
  color: #fff;
}

.simple-page-form {
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 24px;
}
.simple-page-form .form-group {
  margin-bottom: 32px;
}
.simple-page-form input,
.simple-page-form input:focus,
.simple-page-form input:active {
  outline: none;
  box-shadow: none;
}
.simple-page-form input {
  /*  border: none;
    border-bottom: 1px solid #eee;   */
  height: 40px;
}

.simple-page-form input.input-small {
  /*  border: none;
    border-bottom: 1px solid #eee;   */
  height: 30px !important;
}

.simple-page-form .btn {
  width: 100%;
}

.simple-page-footer p {
  text-align: center;
  margin-bottom: 24px;
}

.simple-page-footer a {
  color: #fff;
  font-weight: 500;
}

.simple-page-footer small {
  margin-right: 16px;
  color: #fff;
}

#_404_title {
  text-align: center;
  font-weight: 900;
  font-size: 140px;
  letter-spacing: 5px;
  color: #fff;
}

#_404_msg {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

#_404_form {
  margin-top: 48px;
}
#_404_form .form-control {
  height: 40px;
}
#_404_form .input-group-addon {
  background: $theme-primary;
  border: $theme-primary;
  color: #fff;
}

#unlock-user {
  margin-top: 64px;
  margin-bottom: 48px;
}
#unlock-user .avatar {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
}
#unlock-user h4 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

#unlock-form .form-group {
  margin-bottom: 32px;
}

#unlock-form input,
#unlock-form input:focus,
#unlock-form input:active {
  outline: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #aaa;
}

#unlock-form input {
  width: 70%;
  margin: 0 auto;
  border-radius: 0;
  text-align: center;
  color: #fff;
  background-color: transparent;
  height: 40px;
}
#unlock-form input::-webkit-input-placeholder {
  color: #fff;
}
#unlock-form input::-moz-placeholder {
  color: #fff;
}
#unlock-form input:-ms-input-placeholder {
  color: #fff;
}

#unlock-form #unlock-btn {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  color: $theme-primary;
  border-radius: 3px;
  width: 120px;
}

#back-to-home {
  position: fixed;
  top: 60px;
  left: 60px;
}

#back-to-home a {
  color: #fff;
  font-size: 18px;
}
#back-to-home a i {
  font-size: 24px;
}

/*hide back to home button*/

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #back-to-home {
    display: none;
  }
}

/*PROVIDER TABLE*/

.hiddenRow {
  display: none;
}

#provider_table table {
  width: 100%;
}

#provider_table td:first-child,
#provider_table th:first-child {
  width: 20%;
}

@media screen and (min-width: 768px) {
  .mobile_break {
    display: none !important;
  }
}

#locations_table table {
  width: 100%;
}

#locations_table td:first-child,
#locations_table th:first-child {
  width: 20%;
}

#auth_table td {
  width: 50%;
}
